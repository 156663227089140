.txt-color-blue {
  color: #57889c !important;
}
.txt-color-blueLight {
  color: #92a2a8 !important;
}
.txt-color-blueDark {
  color: #4c4f53 !important;
}
.txt-color-green {
  color: #356e35 !important;
}
.txt-color-greenLight {
  color: #71843f !important;
}
.txt-color-linkGreen {
  color: #93c54b !important;
}
.txt-color-greenDark {
  color: #496949 !important;
}
.txt-color-red {
  color: #a90329 !important;
}
.txt-color-yellow {
  color: #b09b5b !important;
}
.txt-color-orange {
  color: #b19a6b !important;
}
.txt-color-orangeDark {
  color: #a57225 !important;
}
.txt-color-pink {
  color: #ac5287 !important;
}
.txt-color-pinkDark {
  color: #a8829f !important;
}
.txt-color-purple {
  color: #6e587a !important;
}
.txt-color-darken {
  color: #404040 !important;
}
.txt-color-lighten {
  color: #d5e7ec !important;
}
.txt-color-white {
  color: #ffffff !important;
}
.txt-color-grayDark {
  color: #525252 !important;
}
.txt-color-magenta {
  color: #6e3671 !important;
}
.txt-color-teal {
  color: #568a89 !important;
}
.txt-color-redLight {
  color: #a65858 !important;
}
.dashboardRow {
  margin-top: 25px;
}
.dashboardIcons {
  font-size: 54px;
}
.dashboardImage {
  vertical-align: middle;
}
.pointer-cursor {
  cursor: pointer;
}
.cl-mcont .block .stat-data,
.cl-mcont .block-flat .stat-data {
  width: 80%;
}
.cl-mcont .block .stat-data .stat-blue,
.cl-mcont .block-flat .stat-data .stat-blue {
  padding: 10px 5px;
}
.cl-mcont .block .stat-data .stat-green,
.cl-mcont .block-flat .stat-data .stat-green {
  background: #227829;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  color: #FFF;
  padding: 10px 5px;
}
.cl-mcont .block .stat-data .stat-lightGreen,
.cl-mcont .block-flat .stat-data .stat-lightGreen {
  background: #457a49;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  color: #FFF;
  padding: 10px 5px;
}
.cl-mcont .block .stat-data .stat-orange,
.cl-mcont .block-flat .stat-data .stat-orange {
  background: #787122;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  color: #FFF;
  padding: 10px 5px;
}
.cl-mcont .block .stat-data .stat-lightOrange,
.cl-mcont .block-flat .stat-data .stat-lightOrange {
  background: #7c784c;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  color: #FFF;
  padding: 10px 5px;
}
.cl-mcont .block .stat-data .stat-lightRed,
.cl-mcont .block-flat .stat-data .stat-lightRed {
  background: #d77171;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  color: #FFF;
  padding: 10px 5px;
}
.block-flat .header {
  color: #a90329;
}
.has-feedback .form-control {
  padding-right: 21.5px;
}
select.form-control,
textarea.form-control {
  padding: 3px 4px;
}
.form-group-narrow {
  margin-top: 1px;
  margin-bottom: 5px;
  padding: 3px 4px;
}
.modal-header-narrow {
  min-height: 10.43px;
  padding: 8px;
}
.modal-footer-narrow {
  padding: 8px;
}
.form-control-narrow {
  height: 28px;
}
.form-control-required {
  background-color: #a6c8ff;
  font-weight: bold;
}
.differentAddressElement, .differentAddressElement td{
  color: #a90329 !important;
}
.underlineText {
  text-decoration: underline;
}
.pageScript {
  font-weight: bold;
}
.btn-fixed-width {
  width: 200px;
}
.btn-centered {
  margin: 0 auto;
  display: block;
}
.report-table table td {
  border-collapse: collapse !important;
  border: 0 !important;
}
.errorLabel {
  color: #a90329;
  font-size: 20px;
  font-weight: bold;
}
.navbar-nav-tophead > li > a {
  padding-top: 50px !important;
}
.body-offsets {
  margin-top: 110px;
  margin-bottom: 70px;
}
.drtv .body-offsets {
  margin: 0;
}
@media (max-width: 767px) {
  .navbar-tophead {
    min-height: 60px !important;
  }
  .navbar-nav-tophead > li > a {
    padding-top: 20px !important;
  }
  .body-offsets {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
.cmn-form-control-has-error,
.braintree-hosted-fields-invalid{
  border-color: #d9534f;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}


.braintree-form__notice-of-collection{
  margin-left: 30px !important;
}

.braintree-show-methods [data-braintree-id='methods-label'], .braintree-show-methods [data-braintree-id='methods-edit'], .braintree-show-options [data-braintree-id='choose-a-way-to-pay']{
  text-transform: capitalize;
  font-weight: 600;
}

.grecaptcha-badge {
  bottom: 85px !important;
}