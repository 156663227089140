/*#region Splash */
#splash-page {
    z-index: 99999 !important;
}

    #splash-page .bar {
        width: 100%;
    }

.page-splash {
    z-index: 99999 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    opacity: 1;
    pointer-events: auto;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.page-splash-message {
    text-align: center;
    margin: 20% auto 0 auto;
    font-size: 400%;
    font-family: "Segoe UI", Arial, Helvetica, sans-serif;
    font-weight: normal;
    -webkit-text-shadow: 2px 2px #000000;
    text-shadow: 2px 2px #000000;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    text-decoration: none;
    color: #F58A00;
    padding: 0;
}

    .page-splash-message.page-splash-message-subtle {
        margin: 30% auto 0 auto;
        font-size: 200%;
    }

.fa-asterisk.fa-asterisk-large {
    font-size: 180%;
    vertical-align: middle;
    color: #F58A00;
}

.fa-asterisk.fa-asterisk-alert {
    color: #F58A00;
}

.fa-asterisk-inline {
    padding: 0 4px 0 0;
}


.progress,
.page-progress-bar {
    margin: 30px 10% !important;
}

.spinner {
    margin: 20% auto 0 auto;
    left: auto;
    top: auto !important;
}
/*#endregion*/



/*#region Angular ng-show dissolve animation */
.dissolve-animation.ng-hide-remove {
    display: inline !important;
}

.dissolve-animation.ng-hide-remove,
.dissolve-animation.ng-hide-add {
    -webkit-transition: 0.8s linear all;
    -moz-transition: 0.8s linear all;
    -o-transition: 0.8s linear all;
    transition: 0.8s linear all;
}

    .dissolve-animation.ng-hide-remove.ng-hide-remove-active,
    .dissolve-animation.ng-hide-add {
        opacity: 1;
    }

        .dissolve-animation.ng-hide-add.ng-hide-add-active,
        .dissolve-animation.ng-hide-remove {
            opacity: 0;
        }
/*#endregion */

/*#region Angular ng-include, ng-view, ng-repeat shuffle animations*/

.shuffle-animation.ng-enter,
.shuffle-animation.ng-leave {
    position: relative;
}

.shuffle-animation.ng-enter {
    -moz-transition: ease-out all 0.3s 0.4s;
    -o-transition: ease-out all 0.3s 0.4s;
    -webkit-transition: ease-out all 0.3s 0.4s;
    transition: ease-out all 0.3s 0.4s;
    left: 2em;
    opacity: 0;
}

    .shuffle-animation.ng-enter.ng-enter-active {
        left: 0;
        opacity: 1;
    }

.shuffle-animation.ng-leave {
    -moz-transition: 0.3s ease-out all;
    -o-transition: 0.3s ease-out all;
    -webkit-transition: 0.3s ease-out all;
    transition: 0.3s ease-out all;
    left: 0;
    opacity: 1;
}

    .shuffle-animation.ng-leave.ng-leave-active {
        left: 2em;
        opacity: 0;
    }
/*#endregion*/


/*#region Form Login*/
form-login {
    max-width: 330px;
    padding: 0 15px 5px 15px;
    margin: 0 auto;
}

.form-login .form-login-heading {
    margin-bottom: 10px;
}

.form-login .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}

    .form-login .form-control:focus {
        z-index: 2;
    }

.form-login input[type="password"] {
    margin-top: 5px;
    margin-bottom: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
/*#endregion*/

.pointer {
    cursor: pointer;
}

.footer {
    text-align: right !important;
    padding-top: 35px;
    font-size: 11px;
    color: #ffffff;
}
/* original css below */
#banner-img {
    width: 100%;
}

#money-box-div {
    text-align: center;
    margin-left: 0 auto;
}

.money-box {
    background-color: #80c2e5;
    color: white;
    border: 1px solid #d12e3b;
    text-align: center;
    display: inline-block;
    font-size: 3vw;
    width: 10%;
    line-height: 300%;
    margin-left: 1%;
}

body {
    text-align: center;
}

h2 {
    text-align: left;
    margin-left: 15%;
}

a {
    text-decoration: none;
}

input {
    display: inline-block;
    font-size: 24px;
    border: 2px solid #0084cb;
    margin-top: 2%;
    color: #0084cb;
    padding: 2%;
}

.cc,
.info,
.eCheck,
.dedicate {
    width: 70%;
}



body {
    margin: auto;
    font-family: "Proxima Nova Soft","Helvetica", "Arial", sans-serif;
}

#exp-date {
    color: #1a1a1a;
    border: none;
}

#exp-month {
    width: 10%;
    text-align: center;
}

#exp-year {
    width: 15%;
    text-align: center;
}

label {
    text-align: left;
    float: left;
    margin-left: 15%;
}

.checkbox-small {
    font-size: 16px;
    padding-left: 2.7%;
}

.checkboxes label {
    display: inline-block;
}

.checkbox-label {
    display: none;
}

label, #notify {
    border: none;
    font-size: 22px;
}

.dedicate-label {
    display: none;
}

#credit-card-box {
    background-color: #80c2e5;
    color: white;
    border: 1px solid #d12e3b;
    text-align: center;
    display: inline-block;
    font-size: 32px;
    padding: 2%;
    width: 25%;
}

#eCheck-box {
    background-color: #80c2e5;
    color: white;
    border: 1px solid #d12e3b;
    text-align: center;
    display: inline-block;
    font-size: 32px;
    padding: 2%;
    margin-left: 2%;
    width: 25%;
}

.cc {
    display: inline-block;
    font-size: 24px;
    padding: 2%;
}

#checkboxes {
    display: inline-block;
    margin-left: 10%;
}

.cc-label {
}

.eCheck-label {
    display: none;
}

.info {
    padding: 2%;
    display: inline-block;
}

.eCheck {
    display: none;
    font-size: 24px;
}

.dedicate {
    display: none;
    width: 70%;
    font-size: 24px;
    border: 2px solid #0084cb;
    padding: 5px;
    margin-top: 2%;
    color: #0084cb;
    padding: 2%;
}

#radio-station-dropdown, .comments {
    display: inline-block;
    width: 70%;
    font-size: 24px;
    border: 2px solid #0084cb;
    padding: 5px;
    margin-top: 2%;
    color: #0084cb;
    padding: 2%;
}

#radio-station-dropdown {
    margin-left: 3%;
    width: 30%;
}

#complete-gift-btn {
    background-color: #0084cb;
    color: white;
    border: 1px solid #d12e3b;
    text-align: center;
    display: inline-block;
    font-size: 3vw;
    width: 45%;
    padding: 4%;
    margin: 0 auto;
}

select {
    display: inline-block;
}

input[placeholder], [placeholder], *[placeholder] {
    color: #0084cb !important;
}

input, select, textarea {
    color: #0084cb;
    border: 2px solid #0084cb;
}

    input::-webkit-input-placeholder {
        color: #0084cb !important;
    }

    input:-moz-placeholder { /* Firefox 18- */
        color: #0084cb !important;
    }

    input::-moz-placeholder { /* Firefox 19+ */
        color: #0084cb !important;
    }

    input:-ms-input-placeholder {
        color: #0084cb !important;
    }


/*                          */
.btn-keep-text-color:hover:not(.btn-danger),
.btn-keep-text-color:focus:not(.btn-danger),
.btn-keep-text-color:active:not(.btn-danger) {
	color: initial !important;
	background-color: #ccc;
}

.btn-checkingSaving:hover,
.btn-checkingSaving:focus,
.btn-checkingSaving:active,
.btn-checkingSaving {
	color: #333;
	text-transform: none;
	font-size: 14px;
}

.visible-xs .btn-checkingSaving {
    margin-bottom: 5px;
    font-size: unset;
}

.btn-checkingSaving.active {
    margin-bottom: 5px;
    font-size: unset;
    background-color: #ccc;
    pointer-events: none;
}

.navbar-inverse {
	background-color: #2494F2;
	border-color: #2494F2;
}

	.navbar-inverse.navbar .nav > li > a {
		text-transform: none;
	}