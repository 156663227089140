@import "assets/styles/variables";
@import "assets/styles/bootstrap.sandstone.css";
@import "assets/styles/font-awesome.css";
@import "assets/styles/styles.css";
@import "assets/styles/xCmn.css";
@import 'ngx-toastr/toastr';
@import "spin.js/spin.css";

/* Proxima Light */
@font-face {
  font-family: "proxima-nova";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_light.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_light.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
/* Proxima Reg */
@font-face {
  font-family: "proxima-nova";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_reg.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_reg.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/* Proxima Bold */
@font-face {
  font-family: "proxima-nova";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_bold.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "proxima-nova";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_bold.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/proxima_nova_bold.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Proxima Extra Bold */
@font-face {
  font-family: "proxima-nova";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/Proxima-Nova-Xbold.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/proxima-nova/Proxima-Nova-Xbold.woff")
      format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/* Gilroy Light */
@font-face {
  font-family: "gilroy";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_light.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_light.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
/* Gilroy Regular */
@font-face {
  font-family: "gilroy";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_regular.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Gilroy Bold */
@font-face {
  font-family: "gilroy";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_bold.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* Gilroy Extra Bold */
@font-face {
  font-family: "gilroy";
  src: url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_extra_bold.woff2")
      format("woff2"),
    url("https://resources.childrensmiraclenetworkhospitals.org/cdn/fonts/gilroy/gilroy_extra_bold.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.texture {
  display: block;
  height: 100vh;
  width: 100%;
  background-color: rgb(51, 51, 51);
  overflow: hidden;
}
.animation-effects {
  position: relative;
  animation: mymove 1s;
  animation-fill-mode: forwards;
}
@keyframes mymove {
  from {
    opacity: 0;
    left: 10%;
  }
  to {
    opacity: 1;
    left: 0px;
  }
}
.animate {
  position: relative;
  animation: easyGo 1s;
  animation-fill-mode: forwards;
}

@keyframes easyGo {
  from {
    left: 0;
  }
  to {
    left: 10%;
    opacity: 0;
  }
}

.drtv {
  font-family: $body-font;

  h1,h2 {
    margin: 0;
  }

  h3 {
    @include gi-font("medium");
  }

  h4 {
    @include gi-font("medium");
  }

  h5 {
    @include gi-font();
  }

  p {
    color: $medium-gray;
  }

  .drtv-donate-section{
    .well{
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .amount-row {
      margin-bottom: 0;
      .well{
        padding: 0;
      }
      label{
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 10px;
        color: $medium-gray;

        &::after{
          display: none;
        }
  
        strong{
          padding-left: 22px;
          display: block;
          font-weight: 400;
        }
      }
    }

    .donation-form-section{
      label{
        color: $alt-gray;

        &:not(.optional)::after{
         content: '*';
        }
      }

      input, select{
        margin-top: 3px !important;
        background-color: #fafafa;
        border-color: $alt-gray;
      }

      button{
        font-weight: 700;
      }

      .donation-submit i{
        display: none;
      }
    }
  }
}

.hide-xs{
  display: none;

  @media (min-width: 768px) {
    display: inline;
  }
    
}

.red-btn,
.white-btn {
  border: 1px solid;
  border-radius: 3px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 12px;
  text-transform: uppercase;
  transition: border 0.25s ease, background-color 0.25s ease;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.red-btn {
  background-color: $cmnh-red;
  color: #fff;
  border-color: $cmnh-red;

  &:hover,
  &:focus {
    background-color: darken($cmnh-red, 10%);
    color: #fff;
  }
}

.white-btn {
  background-color: #fff;
  color: $cmnh-red;
  border-color: #fff;

  &:hover,
  &:focus {
    background-color: darken(#fff, 10%);
    color: $cmnh-red;
  }
}

.yellow-btn{
  display: inline-block;
  font-size: 32px;
  // font-family: "gilroy";
  font-weight: 800;
  text-transform: uppercase;
  color: $almost-black;
  background: $cmnh-yellow;
  border-radius: 100px;
  padding: 10px 55px;
  transition: border 0.25s ease, background-color 0.25s ease;
  appearance: none;
  border-color: transparent;
  letter-spacing: 2px;

  &:hover,
  &:focus {
    background-color: darken($cmnh-yellow, 5%);
    color: $almost-black;
    text-decoration: none;
  }
}

.drtv-donation {
  .well {
    background-color: #fff !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  .btn-danger {
    background-color: $cmnh-red;
    transition: 0.3s ease;

    &:hover {
      background-color: darken($cmnh-red, 10%);
    }
  }

  #donationAmount {
    color: #333 !important;

    &::placeholder {
      color: #333 !important;
    }
  }

  .gift-amount {
    width: 100%;
  }

  .donation-checkbox {
    background-color: #fff !important;
  }

  .form-control-required {
    background-color: #fff;
  }

  .payment-type {
    background-color: #f0f0f0;
    color: #333;
    transition: 0.3s ease;

    &.active {
      color: #fff;
      background-color: $cmnh-red;
    }
  }

  .donation-submit,
  .accept-button,
  .success-button {
    background-color: $cmnh-red;
    transition: 0.3s ease;

    &:hover {
      background-color: darken($cmnh-red, 10%);
    }
  }

  .text-primary {
    color: #333;
  }

  .close-button,
  .continue-button {
    background-color: #fff;
    color: #333;
    border: 2px solid $cmnh-red;
    transition: 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.text-danger {
  color: $cmnh-red;
}

label {
  font-size: 16px;
  float: none;
  width: 100%;
  margin: 0;
}

