$cmnh-red: #c73047;
$cmnh-yellow: #ffcb05;
$cmnh-blue: #00add6;
$medium-gray: #5d5d5d;
$alt-gray: #707070;
$almost-black: #1d1d1d;
$white: #ffffff;

// Breakpoints
$laptop-l-break: 90em; // 1440px
$laptop-break: 80em; // 1280px
$main-break: 63.9375em; // 1023px
$middle-break: 62em; // 992px
$mobile-break: 47.5em; // 768px
$smaller-break: 37.5em; // 600px
$real-small: 30em; // 480px

// All da fonts
$body-font: "proxima-nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

$gilroy-font: "gilroy", Arial, sans-serif;

@mixin gi-font($font: "regular") {
  @if ($font == "light") {
    font-family: $gilroy-font;
    font-weight: 100;
  }

  @if ($font == "regular") {
    font-family: $gilroy-font;
    font-weight: 400;
  }

  @if ($font == "medium") {
    font-family: $gilroy-font;
    font-weight: 700;
  }

  @if ($font == "bold") {
    font-family: $gilroy-font;
    font-weight: 900;
  }
}
